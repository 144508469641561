$(document).ready(function()
{
    $(".toggle-basket-preview").on("click", function()
    {
        $(".control-basket").tooltip("hide").tooltip("disable");

        $(".basket-preview-overlay").on("click", function()
        {
            $(".control-basket").tooltip("enable");
        });
        $(".basket-preview-header button").on("click", function()
        {
            $(".control-basket").tooltip("enable");
        });
    });

    setTimeout(function()
    {
        $("body").addClass("loaded");
    }, 1000);

    $(".galleryvideo").on("hide.bs.modal", function()
    {
        const url = $(this).find(".yvideo").attr("src");

        $(this).find(".yvideo").attr("src", url);
    });
    $("#erklaervideo").on("hide.bs.modal", function()
    {
        const url = $(this).find("video").attr("src");

        $(this).find("video").attr("src", url);
    });

    $(".shipping-method-select .icon").each(function()
    {
        let bg = $(this).css("background-image");

        bg = bg.replace("url(", "").replace(")", "").replace(/\"/gi, "");

        $(this).click(function()
        {
            $(this).wrap("<a href=\"" + bg + "\" rel=\"lightbox\" />");
            $(this).parent("a").trigger("click");
        });
    });

    $("[data-toggle=\"tooltip\"]").tooltip();

    $(".home-first-list").each(function()
    {
        $(this).owlCarousel({
            autoHeight: false,
            dots: false,
            items: 3,
            lazyLoad: false,
            loop: true,
            center: false,
            margin: 40,
            mouseDrag: true,
            nav: true,
            navClass: [
                "owl-single-item-nav left carousel-control list-control-special",
                "owl-single-item-nav right carousel-control list-control-special"
            ],
            navContainerClass: "",
            navText: [
                "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
            ],
            smartSpeed: 350,
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                768: {
                    items: 1,
                    slideBy: 1
                },
                1200: {
                    items: 3,
                    margin: 20,
                    slideBy: 1
                },
                1350: { margin: 40 }
            }
        });
    });

    $(".custom-list-item-carousel").each(function()
    {
        $(this).owlCarousel({
            autoHeight: false,
            dots: false,
            items: 3,
            lazyLoad: false,
            center: false,
            loop: true,
            margin: 40,
            mouseDrag: true,
            nav: true,
            navClass: [
                "owl-single-item-nav left carousel-control list-control-special",
                "owl-single-item-nav right carousel-control list-control-special"
            ],
            navContainerClass: "",
            navText: [
                "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
            ],
            smartSpeed: 350,
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                768: {
                    items: 1,
                    slideBy: 1
                },
                1200: {
                    items: 3,
                    margin: 20,
                    slideBy: 1
                },
                1350: { margin: 40 }
            }
        });
    });

    $(".cat-highlight").each(function()
    {
        $(this).owlCarousel({
            autoHeight: false,
            dots: false,
            lazyLoad: false,
            loop: true,
            margin: 10,
            mouseDrag: true,
            nav: true,
            navClass: [
                "owl-single-item-nav left carousel-control list-control-special", "owl-single-item-nav right carousel-control list-control-special"
            ],
            navContainerClass: "",
            navText: [
                "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
            ],
            smartSpeed: 350,
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                544: {
                    items: 2,
                    slideBy: 1
                },
                768: {
                    items: 3,
                    slideBy: 1
                },
                1024: {
                    items: 4,
                    slideBy: 1
                }
            }
        });
    });

    $(".big3cats").each(function()
    {
        $(this).owlCarousel({
            autoHeight: false,
            dots: false,
            lazyLoad: false,
            loop: true,
            margin: 10,
            mouseDrag: true,
            nav: true,
            navClass: [
                "owl-single-item-nav left carousel-control-prev carousel-control list-control-special", "owl-single-item-nav right carousel-control-next carousel-control list-control-special"
            ],
            navContainerClass: "",
            navText: [
                "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
            ],
            smartSpeed: 350,
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                544: {
                    items: 3,
                    slideBy: 1
                },
                768: {
                    items: 3,
                    slideBy: 1,
                    loop: true
                },
                1024: {
                    items: 3,
                    slideBy: 1
                }
            }
        });
    });
});

window.onload = function()
{
    $(".home-first-list .cat-item").each(function()
    {
        $(this).addClass("loaded");
    });
};
